import * as React from 'react'

import { useAuth } from '../../context/auth/auth-context.jsx'
import Logo from '../../images/logo.png'
import { socket } from '../../socket'
import { useDebounce } from '../../utils/hooks/debounce'
import useEvent from '../../utils/hooks/event'
import { Page } from '../styles'

import { UjianResultDialog } from './components'
import UjianCountdown from './ujian-countdown.jsx'
import UjianEndDialog from './ujian-end-dialog.jsx'

import { DateTime } from 'luxon'
import { Button, Card, Col, Container, Dropdown, Form, Nav, Navbar, NavItem, NavLink, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const Ujian = () => {
  const { ujianId } = useParams()
  const { user, logout } = useAuth()
  const navigate = useNavigate()
  const { getQuestions, getAnswers, loginExam, getExamStatus, lockExam, setAnswer } = useEvent()
  const [questions, setQuestions] = React.useState([])
  const [tempData, setTempData] = React.useState([])
  const [answerList, setAnswerList] = React.useState([])
  const [examLoggedIn, setExamLoggedIn] = React.useState(false)
  const [isError, setIsError] = React.useState('')
  const [examStatus, setExamStatus] = React.useState(null)
  // A state to store jawaban in the client side
  const [jawaban, setJawabanVal] = React.useState([])
  const [nomer, setNomer] = React.useState(0)
  const [terminate, setTerminate] = React.useState(false)
  const [resultOpen, setResultOpen] = React.useState('')
  const debData = useDebounce(tempData, 1000)

  React.useEffect(() => {
    socket.connect()
    socket.emit('PING')

    return () => {
      socket.disconnect()
    }
  }, [])

  React.useEffect(() => {
    if (isError === 'ujian telah berakhir / waktu tidak cukup / locked') {
      lockExam(ujianId)
        .then(() => {
          toast.error('Ujian telah berakhir')
        })
        .catch((err) => {
          console.error(err.message)
        })
        .finally(() => {
          setTimeout(() => navigate('/beranda'), 2500)
        })
    }
  }, [isError, lockExam, navigate, ujianId])

  /**  A function to handle answer list from BE and put it into state */
  const putAnswerToState = React.useCallback((data) => {
    let listTmp = {}

    if (Array.isArray(data)) {
      listTmp = data
        .filter((val) => val !== 'Kosong' && (val.pilihan || val.essay))
        .reduce((obj, item) => {
          obj[`num-${item.soal_id}`] = item
          return obj
        }, {})
    }

    setAnswerList(listTmp)
  }, [])

  React.useEffect(() => {
    putAnswerToState(debData)
  }, [debData, putAnswerToState])

  React.useEffect(() => {
    if (examLoggedIn) {
      getExamStatus(ujianId).then(setExamStatus)
      getQuestions(ujianId).then(async (res) => {
        setQuestions(res)
        try {
          const answers = await getAnswers(ujianId)
          putAnswerToState(answers)
        } catch (error) {
          console.error(error)
          toast.error('Kesalahan saat mengambil jawaban. Silakan muat ulang halaman.')
        }
      })
    } else {
      loginExam(ujianId)
        .then((res) => {
          setExamLoggedIn(!res.error)
          setIsError('')
        })
        .catch((err) => {
          console.error(err.message)
          setIsError(err.message)
          if (err.message !== 'ujian telah berakhir / waktu tidak cukup / locked') {
            toast.error('Kesalahan saat masuk ujian. Silakan muat ulang halaman.')
          }
        })
    }
  }, [examLoggedIn])

  React.useEffect(() => {
    if (examLoggedIn) {
      socket.on('jawaban-update', (res) => {
        setTempData(res)
      })
    }

    return () => {
      socket.off('jawaban-update')
    }
  }, [examLoggedIn])

  React.useEffect(() => {
    if (questions.length > 0) {
      for (let i = 0; i < questions.length; i++) {
        // state dirubah supaya panjang index sejumlah soal
        setJawabanVal((jwbn) => [...jwbn, ''])
      }
    }
  }, [questions])

  React.useEffect(() => {
    if (Object.keys(answerList).length > 0) {
      const tmp = [...jawaban]
      const questionsTmp = {}
      let idx = 0

      questions.forEach((item) => (questionsTmp[`num-${item.id}`] = item))

      // eslint-disable-next-line guard-for-in
      for (const key in questionsTmp) {
        if (answerList.hasOwnProperty(key)) {
          if (questionsTmp[key].tipe_soal === 'essay') {
            tmp[idx] = answerList[key].essay === null ? '' : answerList[key].essay
          } else tmp[idx] = answerList[key].pilihan
        } else {
          tmp[idx] = ''
        }
        idx = idx + 1
      }

      setJawabanVal(tmp)
    }
  }, [answerList])

  const getDuration = React.useCallback(() => {
    const now = DateTime.local()
    let end = 0
    let utcOffset = 0

    if (now.offset === 480) {
      utcOffset = 60
    } else if (now.offset === 540) {
      utcOffset = 120
    }

    if (examStatus) {
      if (DateTime.fromSQL(examStatus.end) > DateTime.fromSQL(examStatus.login).plus({ minutes: examStatus.durasi })) {
        end = DateTime.fromSQL(examStatus.login).plus({ minutes: examStatus.durasi })
      } else {
        end = DateTime.fromSQL(examStatus.end)
      }
      return end.diff(now).plus({ minutes: utcOffset }).toMillis()
    }

    return end
  }, [examStatus])

  const goNextNum = () => setNomer((prevNomer) => prevNomer + 1)

  const goPrevNum = () => setNomer((prevNomer) => prevNomer - 1)

  const handleTerminate = () => setTerminate((prevStatus) => !prevStatus)

  const onSetAnswer = async (examID, soalId, jwbn) => {
    try {
      await setAnswer(examID, soalId, jwbn)
    } catch (err) {
      console.error(err)
      toast.error('Terjadi kesalahan saat menyimpan jawaban. Silakan muat ulang halaman.')
    }
  }

  const onLockUjian = async () => {
    try {
      await lockExam(ujianId)
      setResultOpen(ujianId)
    } catch (err) {
      console.error(err)
      toast.error('Terjadi kesalahan saat mengakhiri ujian. Silakan muat ulang halaman.')
    }
  }

  return (
    <Page>
      <Navbar expand="md" variant="light">
        <Container fluid="xl">
          <Navbar.Brand className="d-flex align-items-center pr-0 pr-md-3" href="/">
            <i className="fas fa-angle-left mr-2" />
            <img alt="Depa's Infection logo" className="d-inline-block align-top" height="30" src={Logo} width="30" />
          </Navbar.Brand>
          <Nav className="flex-row order-md-last">
            <Dropdown as={NavItem}>
              <Dropdown.Toggle as={NavLink} className="d-flex align-items-center lh-1 text-reset p-0">
                <div className="d-none d-xl-block pl-2">
                  <p className="mb-0">{user ? user.nama : 'Nama Peserta'}</p>
                  <div className="small text-muted">Peserta</div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight={true}>
                <Dropdown.Item onClick={logout}>
                  <i className="fas fa-sign-out-alt" />
                  <span>Keluar</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Container>
      </Navbar>
      <div className="app-body">
        <main className="main mt-3">
          <Container fluid>
            <Row>
              <Col md="8" sm="12">
                <Card className="mb-5">
                  <Card.Header className="d-flex justify-content-between text-dark">
                    <div className="text-left">
                      <Link
                        className={nomer === 0 ? 'text-muted' : ''}
                        onClick={() => {
                          if (nomer !== 0) {
                            onSetAnswer(ujianId, questions[nomer].id, jawaban[nomer])

                            goPrevNum()
                          }
                        }}
                        to="#"
                      >
                        <i className="fas fa-arrow-circle-left" style={{ marginRight: '0.5rem' }} />
                      </Link>
                      {`Soal ${nomer + 1} dari ${questions.length}`}
                      <Link
                        className={nomer === questions.length - 1 ? 'text-muted' : ''}
                        onClick={() => {
                          if (nomer < questions.length - 1) {
                            onSetAnswer(ujianId, questions[nomer].id, jawaban[nomer])

                            goNextNum()
                          }
                        }}
                        to="#"
                      >
                        <i className="fas fa-arrow-circle-right" style={{ marginLeft: '0.5rem' }} />
                      </Link>
                    </div>
                    <div className="text-right">{`${answerList ? Object.keys(answerList).length : 0} jawaban tersimpan`}</div>
                  </Card.Header>
                  <Card.Body>
                    {questions.length > 0 ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: questions[nomer].soal
                        }}
                      />
                    ) : null}
                    <p>
                      <strong>Jawaban:</strong>
                    </p>
                    <Form.Group tag="fieldset">
                      <Col sm={10}>
                        {questions.length > 0 ? (
                          questions[nomer].tipe_soal === 'essay' ? (
                            <Form.Group controlId="formJawabEssay">
                              <Form.Control
                                as="textarea"
                                name={`essay-${nomer}`}
                                onChange={(e) => {
                                  // onChange untuk input jawaban soal essay
                                  const valTemp = [...jawaban]
                                  valTemp[nomer] = e.target.value
                                  setJawabanVal(valTemp)
                                }}
                                rows="2"
                                value={jawaban[nomer]}
                              />
                            </Form.Group>
                          ) : (
                            questions[nomer].pilihan.map((pil, idx) => (
                              <Form.Group key={idx} className="d-flex">
                                <Form.Check
                                  checked={(idx + 10).toString(36).toUpperCase() === jawaban[nomer]}
                                  label={`${pil.label}.`}
                                  name={`radio-${nomer}`}
                                  onChange={() => {
                                    // onChange untuk input jawaban soal pilihan
                                    const valTemp = [...jawaban]
                                    valTemp[nomer] = (idx + 10).toString(36).toUpperCase()
                                    setJawabanVal(valTemp)

                                    onSetAnswer(ujianId, questions[nomer].id, valTemp[nomer])
                                  }}
                                  type="radio"
                                />{' '}
                                <span
                                  className="ms-1 text-dark"
                                  dangerouslySetInnerHTML={{
                                    __html: pil.value
                                  }}
                                />
                              </Form.Group>
                            ))
                          )
                        ) : null}
                      </Col>
                    </Form.Group>
                    <Button
                      onClick={() => {
                        const valTmp = [...jawaban]
                        valTmp[nomer] = ''
                        setJawabanVal(valTmp)

                        onSetAnswer(ujianId, questions[nomer].id, valTmp[nomer])
                      }}
                      variant="link"
                    >
                      Bersihkan jawaban
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <div className="text-right">
                      {questions.length > 0 ? (
                        questions[nomer].tipe_soal === 'pilihan' || questions[nomer].tipe_soal === 'essay' ? (
                          <>
                            <span className="text-success me-2">{`Benar: ${questions[nomer].benar}`}</span>
                            <span className="text-danger me-2">{`Salah: ${questions[nomer].salah}`}</span>
                            <span className="text-dark me-2">{`Kosong: ${questions[nomer].kosong}`}</span>
                          </>
                        ) : (
                          <span className="text-success">Setiap opsi jawaban memiliki nilai berbeda-beda.</span>
                        )
                      ) : null}
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
              <Col md="4" sm="12">
                <Card>
                  <Card.Header>
                    <span className="h5 text-dark">Sisa Waktu</span>
                  </Card.Header>
                  <Card.Body>
                    <UjianCountdown getDuration={getDuration} lockUjian={onLockUjian} />
                  </Card.Body>
                  <Card.Header>
                    <span className="h5 text-dark">Daftar Soal</span>
                  </Card.Header>
                  <Card.Body>
                    <Container>
                      <Row>
                        {questions
                          ? questions.map((soal, idx) => (
                              <NumButton
                                key={soal.id}
                                answered={jawaban[idx] !== ''}
                                color="info"
                                current={nomer === idx}
                                onClick={() => {
                                  onSetAnswer(ujianId, questions[nomer].id, jawaban[nomer])

                                  setNomer(idx)
                                }}
                              >
                                {idx + 1}
                              </NumButton>
                            ))
                          : null}
                      </Row>
                      <Row className="mt-5">
                        <Button
                          className="m-auto"
                          onClick={() => {
                            handleTerminate()
                            if (questions[nomer].tipe_soal === 'essay') {
                              onSetAnswer(ujianId, questions[nomer].id, jawaban[nomer])
                            }
                          }}
                          variant="primary"
                        >
                          Selesai
                        </Button>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <UjianEndDialog handleClose={handleTerminate} id={ujianId} lockUjian={onLockUjian} open={terminate} />
            <UjianResultDialog
              handleClose={() => {
                setResultOpen(null)
                setExamLoggedIn(false)
              }}
              id={ujianId}
              open={resultOpen}
              showCongrats
            />
          </Container>
        </main>
      </div>
    </Page>
  )
}

const NumButton = styled.button`
  display: inline-block;
  width: auto;
  margin: 10px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 50%;
  border-color: #63c2de;
  color: ${(props) => (props.current ? '#fff' : '#63c2de')};
  background-color: ${(props) => (props.current ? '#63c2de' : 'transparent')};
  color: ${(props) => props.answered && '#fff !important'};
  background-color: ${(props) => props.answered && '#007bff !important'};
  &:hover {
    color: #fff;
    background-color: ${(props) => (props.current ? '#2E87A0' : '#63c2de')};
  }
`

export default Ujian
