import TopBarProgress from 'react-topbar-progress-indicator'

TopBarProgress.config({
  barColors: {
    0: '#3282b8',
    '1.0': '#3282b8'
  },
  shadowBlur: 5
})

export { TopBarProgress }
