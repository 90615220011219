import * as React from 'react'

import Footer from '../../components/layouts/footer.jsx'
import Header from '../../components/layouts/header.jsx'
import Hero from '../../components/layouts/hero.jsx'
import { TopBarProgress } from '../../components/topbar-progress.jsx'
import { EventProviders } from '../../context/event'
import { Content, Page } from '../../pages/styles'

import UjianOpener from './views/entrance.jsx'
import UjianHistory from './views/history.jsx'
import Home from './views/home.jsx'

import { Outlet, Route, Routes } from 'react-router-dom'

function Layout() {
  return (
    <Page>
      <Header />
      <Hero />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </Page>
  )
}

function Dashboard() {
  return (
    <Routes>
      <Route
        element={
          <React.Suspense fallback={<TopBarProgress />}>
            <EventProviders>
              <Layout />
            </EventProviders>
          </React.Suspense>
        }
        path="/"
      >
        <Route element={<Home />} index />
        <Route element={<UjianHistory />} path="ujian/riwayat">
          <Route element={<UjianHistory />} path=":ujianId" />
        </Route>
        <Route element={<UjianOpener />} path="ujian/:ujianId" />
      </Route>
    </Routes>
  )
}

export default Dashboard
