import React from 'react'

import { useAuth } from '../../context/auth/auth-context.jsx'
import Ava from '../../images/default-avatar.png'
import Logo from '../../images/logo.png'

import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Header = () => {
  const { user, logout } = useAuth()
  const location = useLocation()
  const navKey = location.pathname

  return (
    <HeaderArea>
      <Navbar expand="md" variant="light">
        <Container fluid="xl">
          <button
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbarSupportedContent"
            data-toggle="collapse"
            type="button"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <Navbar.Brand className="pe-0 pe-md-3" href="/beranda">
            <img alt="Depa's Infection logo" className="d-inline-block align-top" height="30" src={Logo} width="30" />
          </Navbar.Brand>
          <Nav className="order-md-last">
            <NavDropdown
              align="right"
              title={
                <div className="d-flex align-items-center ">
                  <Avatar style={{ backgroundImage: `url(${Ava})` }} />
                  <div className="d-none d-xl-block ps-xl-3">
                    <p className="mb-0">{user ? user.nama : 'Nama Pengguna'}</p>
                    <div className="small text-muted">participant</div>
                  </div>
                </div>
              }
            >
              <NavDropdown.Item className="d-flex align-items-center" onClick={logout}>
                <i className="fas fa-sign-out-alt pe-2" />
                <span>Keluar</span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
      <div className="navbar-expand-md">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <Navbar variant="light">
            <Container fluid="xl">
              <Nav as="ul" className="me-auto" defaultActiveKey={navKey}>
                <Nav.Item as="li" className="mr-3">
                  <Nav.Link as={Link} eventKey="/beranda" to="/beranda">
                    <span>Beranda</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="mr-3">
                  <Nav.Link as={Link} eventKey="/beranda/ujian/riwayat" to="/beranda/ujian/riwayat">
                    <span>Riwayat Ujian</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Container>
          </Navbar>
        </div>
      </div>
    </HeaderArea>
  )
}

const HeaderArea = styled('header')`
  position: relative;
  width: 100%;
`

const Avatar = styled('span')`
  position: relative;
  width: 2rem;
  height: 2rem;
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: #6e7582;
  text-align: center;
  text-transform: uppercase;
  vertical-align: bottom;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #f1f3f8 no-repeat center/cover;
  border-radius: 50%;
`

export default Header
