import { apiURL } from './utils'

const localStorageKey = '__depas_olimp_token__'

async function getToken() {
  // if we were a real auth provider, this is where we would make a request
  // to retrieve the user's token. (It's a bit more complicated than that...
  // but you're probably not an auth provider so you don't need to worry about it).
  return window.localStorage.getItem(localStorageKey)
}

function handleUserResponse({ data }) {
  window.localStorage.setItem(localStorageKey, data)
  return data
}

function login({ email, password }) {
  return client('peserta/login', { email, password }).then(handleUserResponse)
}

async function logout() {
  window.localStorage.removeItem(localStorageKey)
}

async function client(endpoint, data) {
  const config = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' }
  }

  return window.fetch(`${apiURL}/${endpoint}`, config).then(async (response) => {
    const res = await response.json()
    if (response.ok) {
      return res
    } else {
      return Promise.reject(res)
    }
  })
}

export { getToken, localStorageKey, login, logout }
