import React from 'react'

import Img from '../../images/time-out.svg'

import { Card, Modal } from 'react-bootstrap'

const UjianTimeoutDialog = ({ open, handleClose }) => (
  <Modal backdrop="static" centered keyboard={false} onHide={handleClose} show={open}>
    <Modal.Header closeButton />
    <Modal.Body>
      <Card.Img className="my-3" src={Img} variant="top" />
      <Card.Body className="text-center">
        <Card.Title>
          <h1>Time Out</h1>
        </Card.Title>
        <Card.Text>Waktu Anda telah habis.</Card.Text>
      </Card.Body>
    </Modal.Body>
  </Modal>
)

export default UjianTimeoutDialog
