import 'react-toastify/dist/ReactToastify.css'

import * as React from 'react'

import { AuthProvider, useAuth } from './auth-context.jsx'

import { BrowserRouter, Navigate, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

const RequireAuth = ({ children }) => {
  const { user } = useAuth()
  const location = useLocation()

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate replace state={{ from: location }} to="/login" />
  }

  return children
}

const AuthProviders = ({ children }) => {
  return (
    <BrowserRouter>
      <ToastContainer autoClose={2500} position="bottom-center" rtl={false} />
      <AuthProvider>{children}</AuthProvider>
    </BrowserRouter>
  )
}

export { AuthProviders, RequireAuth }
