import * as React from 'react'

import coverUjian from '../../../images/cover-ujian.png'

import UjianResultDialog from './ujian-result-dialog.jsx'

import { DateTime } from 'luxon'
import { Badge, Button, Card, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

function UjianList({ ujianList, ujianHistory = false, ...props }) {
  const [timeOffset, setTimeUtcOffset] = React.useState(0)

  const getUtcOffset = React.useCallback(() => {
    const now = DateTime.local()
    let utcOffset = 0

    if (now.offset === 480) {
      utcOffset = 60
    } else if (now.offset === 540) {
      utcOffset = 120
    }

    setTimeUtcOffset(utcOffset)
  }, [])

  React.useEffect(() => {
    getUtcOffset()
  }, [getUtcOffset])

  return ujianList.map((ujian) => (
    <Col key={ujian.id} md="4" sm="12">
      <Card className="mt-3">
        <div className="card-img-top position-relative">
          {ujian.mengikuti === 1 ? <BadgeStatus bg="info">Sudah Diikuti</BadgeStatus> : null}
          <Caption>
            <CaptionContent>
              <h2>{ujian.nama}</h2>
            </CaptionContent>
          </Caption>
          <Card.Img bg="top" src={coverUjian} />
        </div>
        <Card.Body>
          <Card.Text>
            <i className="far fa-calendar-alt me-1" />
            {`${DateTime.fromSQL(ujian.start).toFormat('dd MMM yyyy, HH:mm')} WIB - ${DateTime.fromSQL(ujian.end).toFormat(
              'dd MMM yyyy, HH mm'
            )}`}
          </Card.Text>
          <span className="d-flex">
            <Card.Text className="me-3">
              <i className="far fa-edit me-1" />
              {`${ujian.total_soal} soal`}
            </Card.Text>
            <Card.Text>
              <i className="far fa-clock me-1" />
              {`${ujian.durasi} menit`}
            </Card.Text>
          </span>
          <Card.Text className="text-secondary">{ujian.deskripsi}</Card.Text>
        </Card.Body>
        {ujianHistory ? (
          <Card.Footer className="d-flex justify-content-center">
            <Button bg="info" onClick={() => props.toggleResultDialogOpen(ujian.id)}>
              Lihat Hasil
            </Button>
            <UjianResultDialog
              handleClose={() => props.toggleResultDialogClose()}
              id={ujian.id}
              open={props.openResult}
              showCongrats={false}
            />
          </Card.Footer>
        ) : (
          <Card.Footer className="d-flex justify-content-between">
            {DateTime.local() < DateTime.fromSQL(ujian.start).plus({ minutes: timeOffset }) ? (
              <span className="text-secondary text-uppercase ms-auto">Belum dimulai</span>
            ) : DateTime.local() > DateTime.fromSQL(ujian.end).plus({ minutes: timeOffset }) ||
              (DateTime.local() >
                DateTime.fromSQL(ujian.logs.length > 0 ? ujian.logs[0].login_at : ujian.start)
                  .plus({ minutes: timeOffset })
                  .plus({ minutes: ujian.durasi }) &&
                ujian.mengikuti === 1) ? (
              <Badge bg="success" className="ms-auto" style={{ fontSize: '14px', fontWeight: '400' }}>
                Sudah Berakhir
              </Badge>
            ) : (
              <Link className="text-primary text-uppercase ms-auto" to={`/beranda/ujian/${ujian.id}`}>
                {ujian.mengikuti > 0 ? 'Lanjutkan Ujian' : 'Ikuti Ujian'}
              </Link>
            )}
          </Card.Footer>
        )}
      </Card>
    </Col>
  ))
}

const Caption = styled.span`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(33, 37, 41, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  @media (min-width: 769px) {
    -webkit-transition: -webkit-clip-path 0.25s ease-out, background-color 0.7s;
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
`

const CaptionContent = styled.span`
  color: #fff;
  margin: auto 2rem 2rem;
  @media (min-width: 769px) {
    transition: opacity 0.25s;
    margin-right: 5rem;
  }
  & > h2 {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
  }
`

const BadgeStatus = styled(Badge)`
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  z-index: 2;
  right: -20px;
  top: 10px;
`

export default UjianList
