import 'bootstrap/dist/css/bootstrap.min.css'

import * as React from 'react'

import { AuthProviders } from './context/auth'
import App from './app.jsx'

import { createRoot } from 'react-dom/client'

const container = document.getElementById('root')

createRoot(container).render(
  <AuthProviders>
    <App />
  </AuthProviders>
)
