import * as React from 'react'

import { useAuth } from '../../context/auth/auth-context.jsx'
import useEvent from '../../utils/hooks/event'

import { toast } from 'react-toastify'

const DispatchEventContext = React.createContext(null)
const StateEventContext = React.createContext({
  exams: [],
  examLocked: true
})

const examTimeStorageKey = '__olimp_waktu_ujian__'

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'GET_EXAM_LIST':
      return {
        ...state,
        exams: payload
      }
    case 'LOCK_EXAM':
      return {
        ...state,
        examLocked: true
      }
    default:
      throw new Error(`Unknown action type: ${type}`)
  }
}

function EventProvider({ children }) {
  const { getExams } = useEvent()
  const { logout } = useAuth()
  const [state, defaultDispatch] = React.useReducer(reducer, {
    exams: [],
    examLocked: true
  })
  const dispatch = (type, payload) => defaultDispatch({ type, payload })

  React.useEffect(() => {
    getExams().then(
      (res) => dispatch('GET_EXAM_LIST', res),
      () => {
        toast.error('Anda bukan termasuk peserta Olimpiade')
        // Logout in few seconds
        setTimeout(() => {
          logout()
        }, 1500)
      }
    )

    localStorage.removeItem(examTimeStorageKey)
  }, [getExams, logout])

  return (
    <StateEventContext.Provider value={state}>
      <DispatchEventContext.Provider value={dispatch}>{children}</DispatchEventContext.Provider>
    </StateEventContext.Provider>
  )
}

function useStateEvent() {
  const context = React.useContext(StateEventContext)
  if (context === undefined) {
    throw new Error(`useStateEvent must be used within a EventProvider`)
  }
  return context
}

function useDispatchEvent() {
  const context = React.useContext(DispatchEventContext)
  if (context === undefined) {
    throw new Error(`useDispatchEvent must be used within a EventProvider`)
  }
  return context
}

export { EventProvider, useDispatchEvent, useStateEvent }
