import { socketTokenKey } from './utils'

import { io } from 'socket.io-client'

const token = window.localStorage.getItem(socketTokenKey)

export const socket = io('https://sockets.depasinfection.com/', {
  auth: {
    token
  },
  transports: ['polling']
})
