import * as React from 'react'

import * as auth from '../../auth-provider'
import { TopBarProgress } from '../../components/topbar-progress.jsx'
import { http, socketTokenKey } from '../../utils'

export async function bootstrapAppData() {
  let user = null
  const token = await auth.getToken()

  if (token) {
    const response = await http('peserta', { token })
    user = { ...response.data, token }
    window.localStorage.setItem(socketTokenKey, response.data.socket)
  }

  return user
}

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const [error, setError] = React.useState('')
  const [user, setUser] = React.useState()

  React.useEffect(() => {
    setIsLoading(true)
    bootstrapAppData()
      .then((res) => {
        setIsError(false)
        setUser(res)
      })
      .catch((err) => {
        setIsError(true)
        setError(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const login = React.useCallback(
    (form) =>
      auth.login(form).then(async (res) => {
        const response = await http('peserta', { token: res })
        setUser({ ...response.data, token: res })
      }),
    [setUser]
  )
  const logout = React.useCallback(async () => {
    await auth.logout()
    setUser(null)
  }, [setUser])

  const value = React.useMemo(() => ({ user, login, logout }), [login, logout, user])

  if (isLoading) {
    return <TopBarProgress />
  }

  if (isError) {
    return <div>Error: {error}</div>
  }

  return <AuthContext.Provider value={value} {...props} />
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

function useClient() {
  const { user } = useAuth()
  const token = user.token
  return React.useCallback((endpoint, config) => http(endpoint, { ...config, token }), [token])
}

export { AuthProvider, useAuth, useClient }
