import * as React from 'react'

import { useAuth } from '../../../context/auth/auth-context.jsx'
import { useStateEvent } from '../../../context/event/event-context.jsx'

import { DateTime } from 'luxon'
import { Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'

const UjianOpener = () => {
  const { ujianId } = useParams()
  const { exams } = useStateEvent()
  const { user } = useAuth()
  const navigate = useNavigate()
  const examDetail = ujianId ? exams.find((exam) => exam.id === Number(ujianId)) : null
  const [showDialog, setShow] = React.useState(false)

  React.useEffect(() => {
    if (!examDetail) {
      navigate('/beranda')
    }
  }, [examDetail, navigate])

  React.useEffect(() => {
    if (examDetail) {
      localStorage.setItem(
        '__olimp_waktu_ujian__',
        JSON.stringify({
          durasi: examDetail.durasi,
          wkt_selesai: examDetail.end
        })
      )
    }
  }, [examDetail])

  const handleShow = () => setShow(true)

  const handleClose = () => setShow(false)

  if (!examDetail) {
    return null
  }

  return (
    <Container key={examDetail.id} className="my-3">
      <button className="text-primary d-inline-flex border-0 align-items-center" onClick={() => navigate(-1)}>
        <i className="fas fa-angle-left me-2" />
        Kembali
      </button>
      <Row className="mt-3">
        <Col md="6" sm="12">
          <Card className="mb-3">
            <Card.Header>
              <h4>Peraturan Ujian</h4>
            </Card.Header>
            <Card.Body>
              <Card.Text
                as="div"
                dangerouslySetInnerHTML={{
                  __html: examDetail.peraturan
                }}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" sm="12">
          <Card>
            <Card.Header>
              <h4>Detail Ujian</h4>
            </Card.Header>
            <Card.Body className="d-grid gap-3">
              <div>
                <Form.Group controlId="formNamaPeserta">
                  <Form.Label className="text-secondary">Nama Peserta</Form.Label>
                  <Form.Control defaultValue={user ? user.nama : 'Nama Peserta'} plaintext readOnly />
                </Form.Group>
                <Form.Group controlId="formUjian">
                  <Form.Label className="text-secondary">Ujian</Form.Label>
                  <Form.Control defaultValue={examDetail.nama} plaintext readOnly />
                </Form.Group>
                <Form.Group controlId="formDurasi">
                  <Form.Label className="text-secondary">Durasi</Form.Label>
                  <Form.Control defaultValue={`${examDetail.durasi} menit`} plaintext readOnly />
                </Form.Group>
                <Form.Group controlId="formWaktuMulai">
                  <Form.Label className="text-secondary">Waktu Mulai</Form.Label>
                  <Form.Control
                    defaultValue={`${DateTime.fromSQL(examDetail.start).toFormat('dd MMM yyyy, HH:mm')} WIB`}
                    plaintext
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="formWaktuSelesai">
                  <Form.Label className="text-secondary">Waktu Selesai</Form.Label>
                  <Form.Control
                    defaultValue={`${DateTime.fromSQL(examDetail.end).toFormat('dd MMM yyyy, HH:mm')} WIB`}
                    plaintext
                    readOnly
                  />
                </Form.Group>
              </div>
              <div className="d-grid">
                <Button onClick={handleShow} variant="primary">
                  Mulai Ujian
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal backdrop="static" centered keyboard={false} onHide={handleClose} show={showDialog}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p>{`Apakah anda yakin ingin ${examDetail.mengikuti === 1 ? 'melanjutkan' : 'memulai'} ujian sekarang?`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} variant="secondary">
            Batal
          </Button>
          <Link to={`/ujian/${ujianId}`}>
            <Button variant="primary">{examDetail.mengikuti === 1 ? 'Lanjutkan' : 'Mulai'}</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default UjianOpener
