import React from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'

const Footer = () => (
  <FooterArea>
    <Container>
      <Row className="text-center align-items-center flex-row-reverse">
        <Col className="col-lg-auto mt-3 mt-lg-0" lg={12}>
          Copyright © {new Date().getFullYear()} Depa&apos;s Infection. All rights reserved.
        </Col>
      </Row>
    </Container>
  </FooterArea>
)

const FooterArea = styled.footer`
  margin-top: 0;
  background-color: #f5f7fb;
  border-top: 0;
  padding: 50px 0 20px;
  color: #6e7582;
  margin-bottom: -1.25rem;
  position: relative;
  width: 100%;
`

export default Footer
