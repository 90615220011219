import React from 'react'

import { Modal, Table } from 'react-bootstrap'

const KunciJawabanDialog = ({ kunci, open, handleClose }) => (
  <Modal centered onHide={handleClose} show={open} size="lg">
    <Modal.Header closeButton>
      <h3>Kunci Jawaban</h3>
    </Modal.Header>
    <Modal.Body>
      <Table bordered hover striped>
        <thead>
          <tr>
            <th>No. Soal</th>
            <th>Jawaban</th>
            <th>Kunci</th>
            <th>Keterangan</th>
          </tr>
        </thead>
        <tbody>
          {kunci.map((item) => (
            <tr key={item.id}>
              <td>{item.no_soal}</td>
              {item.tipe_soal === 'pilihan' || item.tipe_soal === 'multivalue' ? (
                <>
                  <td>{item.jawaban_peserta ? item.jawaban_peserta.pilihan : '-'}</td>
                  <td>{item.tipe_soal === 'pilihan' ? item.kunci.pilihan : 'Setiap opsi jawaban memiliki nilai berbeda-beda.'}</td>
                  <td>
                    {item.tipe_soal === 'pilihan' && item.jawaban_peserta ? (
                      item.jawaban_peserta.pilihan === item.kunci.pilihan ? (
                        <i className="fas fa-check-circle text-success" />
                      ) : (
                        <i className="fas fa-times-circle text-danger" />
                      )
                    ) : (
                      '-'
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: item.jawaban_peserta.essay
                    }}
                  />
                  <td dangerouslySetInnerHTML={{ __html: item.kunci.essay }} />
                  <td>-</td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal.Body>
  </Modal>
)

export default KunciJawabanDialog
