import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
    }
    html,
    body {
        font-family: "Rubik", sans-serif;
        color: #2222;
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        font-size: 14px;
        position: relative;
    }
    #root > div,
    #root > section,
    #root > header,
    #root > footer {
        overflow-x: hidden;
    }
    #root {
        min-height: 100%;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 700;
        color: #232323;
        line-height: 1.2;
    }
    ul,
    ol {
        margin: 0;
        padding: 0;
    }
    a,
    a:hover,
    a:focus,
    a:active,
    button,
    button:hover {
        text-decoration: none;
        touch-action: manipulation;
        -webkit-transition-duration: 500ms;
        -o-transition-duration: 500ms;
        transition-duration: 500ms;
    }
    li {
        list-style: none;
    }
    p,
    ol,
    label {
        font-size: 14px;
        color: #232323;
    }
    .light {
        color: #767676 !important;
    }
    .yellow {
        color: #f7ed4a !important;
    }
    .btn-ctn {
        border: 1px solid #f7ed4a;
        background-color: #f7ed4a;
        color: #000000;
        border-radius: 24px;
        cursor: pointer;
        display: block;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 22px;
        min-width: 240px;
        outline: none;
        padding: 12px 16px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .btn-ctn:hover {
        border: 1px solid #ffd31d;
        background-color: #ffd31d;
    }
    .btn-ctn:disabled {
        color: #000000;
        border: 1px solid #f7ed4a;
        background-color: #f7ed4a;
    }
    .btn-ctn-outline {
        border: 3px solid #f7ed4a;
        background-color: transparent;
        color: #000000;
        border-radius: 24px;
        cursor: pointer;
        display: block;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 22px;
        min-width: 240px;
        outline: none;
        padding: 12px 16px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .btn-ctn-outline:hover {
        border: 3px solid #f7ed4a;
        background-color: #f7ed4a;
    }
    .btn-ctn:active,
    .btn-ctn-outline:active {
        outline:0
    }
    .display-4 {
        font-size: 38px
    }
    @media(min-width: 992px) {
        .btn-ctn,
        .btn-ctn-outline {
            width: 200px;
        }
        .display-4 {
        font-size: 49px
    }
    }
`

export default GlobalStyle
