import styled from 'styled-components'

export const Page = styled.div`
  overflow-x: unset !important;
  background-color: #f5f7fb;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  .navbar-light {
    box-shadow: 0 0 0 1px rgba(110, 117, 130, 0.2);
    background-color: #fff;
  }
  .navbar-collapse > .navbar {
    flex-grow: 1;
  }
  .navbar-nav .dropdown-menu {
    position: absolute;
  }
  @media (max-width: 768px) {
    .navbar-expand-md .navbar-collapse {
      flex-direction: column;
    }
    .navbar-expand-md .navbar-collapse [class*='container'] {
      flex-direction: column;
      align-items: stretch;
    }
    .navbar-expand-md .navbar-collapse .navbar-nav {
      flex-direction: column;
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }
    .navbar-expand-md .navbar-collapse .navbar-nav .nav-link {
      padding: 0.5rem 0.75rem;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-collapse .dropdown-menu {
      padding: 0;
      background: 0 0;
      position: static;
      color: inherit;
      box-shadow: none;
      border: none;
      -moz-columns: 1;
      columns: 1;
      min-width: 0;
    }
    .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item {
      min-width: 0;
      display: flex;
      width: auto;
      padding-left: 2.25rem;
    }
  }
  @media (min-width: 769px) {
    .dropdown-toggle::after {
      display: none;
    }
    .navbar-expand-md .navbar-light .nav-link.active {
      position: relative;
    }
    .navbar-expand-md .navbar-light .nav-link.active::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: calc(-0.25rem - 1px);
      border: 0 solid #206bc4;
      border-bottom-width: 2px;
    }
  }
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
