import React from 'react'

import cover from '../../images/cover.jpg'

import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'

function Hero() {
  return (
    <HeroArea>
      <Container>
        <Row>
          <Col md={8}>
            <h2 className="display-4">Selamat Datang</h2>
            <p>Peserta Olimpiade Depa&apos;s Infection</p>
          </Col>
        </Row>
      </Container>
    </HeroArea>
  )
}

const HeroArea = styled('header')`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${cover}) no-repeat center center;
  margin-bottom: 40px;
  height: 350px;
  background-size: cover;
  background-position: 30% 50%;
  color: #fff;
  padding-top: 150px;
  text-align: left;
  h2,
  p {
    color: #fff;
  }
`

export default Hero
