import * as React from 'react'

import { useStateEvent } from '../../../context/event/event-context.jsx'
import Img from '../../../images/thanks.svg'
import useEvent from '../../../utils/hooks/event'

import UjianKunciJawaban from './kunci-jawaban-dialog.jsx'

import { Button, Card, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function UjianResultDialog({ id, showCongrats = true, open, handleClose }) {
  const { getExamResult } = useEvent()
  const { examLocked } = useStateEvent()
  const [kunciDialog, setKunciDialog] = React.useState(false)
  const [examResult, setExamResult] = React.useState()

  React.useEffect(() => {
    if (examLocked && open === id) getExamResult(id).then(setExamResult)
  }, [examLocked, getExamResult, id, open])

  const handleKunciJawaban = () => setKunciDialog((prevStatus) => !prevStatus)

  return (
    <Modal
      aria-labelledby={`hasilDetail-${id}-Label`}
      backdrop="static"
      centered
      id={`hasilDetail-${id}`}
      keyboard={false}
      onHide={handleClose}
      show={open === id}
    >
      {showCongrats ? (
        ''
      ) : (
        <Modal.Header closeButton>
          <h3>Hasil Ujian</h3>
        </Modal.Header>
      )}
      <Modal.Body>
        {showCongrats ? <Card.Img className="my-3" src={Img} variant="top" /> : ''}
        <Card.Body className="text-center">
          {showCongrats ? (
            <Card.Title>
              <h1>Terima Kasih Telah Berpartisipasi!</h1>
            </Card.Title>
          ) : (
            ''
          )}
          {examResult && examResult.nilai ? (
            <>
              <Card.Text>Nilai Anda</Card.Text>
              <Card.Text
                style={{
                  fontSize: '36px',
                  fontWeight: '700',
                  color: '#20a8d8'
                }}
              >
                {examResult.nilai}
              </Card.Text>
            </>
          ) : (
            <Card.Text>Nilai anda tidak dapat ditampilkan</Card.Text>
          )}
          {examResult && examResult.kunci ? (
            <>
              <Button onClick={handleKunciJawaban} variant="info">
                Lihat Kunci Jawaban
              </Button>
              <UjianKunciJawaban handleClose={handleKunciJawaban} kunci={examResult.kunci} open={kunciDialog} />
            </>
          ) : (
            ''
          )}
        </Card.Body>
      </Modal.Body>
      {showCongrats ? (
        <Modal.Footer>
          <Button as={Link} className="mx-auto" onClick={handleClose} to="/beranda" variant="link">
            Kembali ke Beranda
          </Button>
        </Modal.Footer>
      ) : (
        ''
      )}
    </Modal>
  )
}

export default UjianResultDialog
