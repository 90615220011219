import React from 'react'

import { Button, Modal } from 'react-bootstrap'

const UjianEndDialog = ({ open, handleClose, lockUjian }) => {
  return (
    <Modal backdrop="static" centered keyboard={false} onHide={handleClose} show={open}>
      <Modal.Header closeButton />
      <Modal.Body>
        <p>Apakah anda yakin ingin mengakhiri ujian?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} variant="secondary">
          Batal
        </Button>
        <Button onClick={lockUjian} variant="primary">
          Akhiri
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UjianEndDialog
