import * as auth from '../auth-provider'
import { apiURL } from '../utils/constant'

async function client(endpoint, { data, token, headers: customHeaders, ...customConfig } = {}) {
  const config = {
    method: data ? 'POST' : 'GET',
    body: data,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      ...customHeaders
    },
    ...customConfig
  }

  return window.fetch(`${apiURL}/${endpoint}`, config).then(async (response) => {
    if (response.status === 401) {
      await auth.logout()
      // refresh the page for them
      window.location.assign(window.location)
      return Promise.reject({ message: 'Please re-authenticate.' })
    }
    const res = await response.json()
    if (response.ok) {
      return res
    } else {
      return Promise.reject(res)
    }
  })
}

export default client
