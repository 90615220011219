import * as React from 'react'

import { useStateEvent } from '../../../context/event/event-context.jsx'
import { UjianList } from '../../../pages/ujian/components'

import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

const UjianHistory = () => {
  const { exams } = useStateEvent()
  const { ujianId } = useParams()
  const examList = exams.filter((exam) => exam.aktif > 0 && exam.mengikuti > 0)
  const [openResult, setOpenResult] = React.useState(null)
  const [oldPath, setOldPath] = React.useState('')

  const toggleResultDialogOpen = (id) => {
    let _oldPath = window.location.pathname
    const newPath = `/beranda/ujian/riwayat/${id}`

    if (_oldPath === newPath) {
      _oldPath = `/beranda/ujian/riwayat`
    }

    window.history.pushState(null, null, newPath)

    setOldPath(oldPath)
    setOpenResult(id)
  }

  React.useEffect(() => {
    if (ujianId) {
      const id = Number(ujianId)
      toggleResultDialogOpen(id)
    }
  }, [ujianId])

  const toggleResultDialogClose = () => {
    window.history.pushState(null, null, oldPath)
    setOpenResult(null)
  }

  return (
    <Container className="my-3">
      <Row>
        <Col md="12">
          <p className="h3 text-primary">Riwayat ujian anda</p>
        </Col>
      </Row>
      <Row className="mt-3">
        {examList.length > 0 ? (
          <UjianList
            openResult={openResult}
            toggleResultDialogClose={toggleResultDialogClose}
            toggleResultDialogOpen={toggleResultDialogOpen}
            ujianHistory={true}
            ujianList={examList}
          />
        ) : (
          <Col className="mx-auto" md="8" sm="12">
            <p className="text-center">Tidak ada ujian yang pernah diikuti</p>
          </Col>
        )}
      </Row>
    </Container>
  )
}

export default UjianHistory
