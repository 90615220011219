import * as React from 'react'

import { useStateEvent } from '../../../context/event/event-context.jsx'
import { UjianList } from '../../../pages/ujian/components'

import { Col, Container, Row } from 'react-bootstrap'

function Home() {
  const { exams } = useStateEvent()
  const examList = exams.filter((exam) => exam.aktif === 1)

  return (
    <Container className="my-3">
      <Row>
        <Col md="12">
          <p className="h3 text-primary">Ujian untuk anda</p>
        </Col>
      </Row>
      <Row className="mt-3">
        {examList.length > 0 ? (
          <UjianList ujianList={examList} />
        ) : (
          <Col className="mx-auto" md="8" sm="12">
            <p className="text-center">Tidak ada ujian yang diikuti</p>
          </Col>
        )}
      </Row>
    </Container>
  )
}

export default Home
