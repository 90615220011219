import * as React from 'react'

import { useClient } from '../../context/auth/auth-context.jsx'

export default function useEvent() {
  const client = useClient()

  const getExams = React.useCallback(async () => {
    const res = await client('peserta/olimpiade/ujian')
    return res.data
  }, [client])

  const getExamResult = React.useCallback(
    async (id) => {
      const res = await client(`peserta/olimpiade/ujian/${id}/result`)
      return res.data
    },
    [client]
  )

  const getExamStatus = React.useCallback(
    async (id) => {
      const res = await client(`peserta/olimpiade/ujian/${id}/status`)
      return res.data
    },
    [client]
  )

  const loginExam = React.useCallback(
    async (id) => {
      const res = await client(`peserta/olimpiade/ujian/${id}/login`, { method: 'POST' })
      return res.data
    },
    [client]
  )

  const lockExam = React.useCallback(
    async (id) => {
      const res = await client(`peserta/olimpiade/ujian/${id}`, { method: 'POST' })
      return res.data
    },
    [client]
  )

  const getQuestions = React.useCallback(
    async (id) => {
      const res = await client(`peserta/olimpiade/ujian/${id}/soal`)
      return res.data
    },
    [client]
  )

  const getAnswers = React.useCallback(
    async (id) => {
      const res = await client(`peserta/olimpiade/ujian/${id}/jawaban`)
      return res.data
    },
    [client]
  )

  const setAnswer = React.useCallback(
    async (id, id_soal, jawaban) => {
      const res = await client(`peserta/olimpiade/ujian/${id}/soal/${id_soal}`, {
        method: 'POST',
        body: JSON.stringify({
          jawaban
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return [res.data]
    },
    [client]
  )

  return {
    getExams,
    getExamResult,
    getExamStatus,
    loginExam,
    lockExam,
    getQuestions,
    getAnswers,
    setAnswer
  }
}
