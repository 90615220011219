import * as React from 'react'

import { RequireAuth } from './context/auth/index.js'
import Login from './pages/auth/login.jsx'
import Dashboard from './pages/dashboard/dashboard.jsx'
import Ujian from './pages/ujian/ujian.jsx'
import GlobalStyle from './styles/index.js'

import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

function App() {
  const location = useLocation()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (location.pathname === '/') {
      navigate('/login')
    }
  }, [location.pathname, navigate])

  return (
    <React.Fragment>
      <GlobalStyle />
      <Routes>
        <Route element={<Login />} path="login" />
        <Route
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
          path="beranda/*"
        />
        <Route
          element={
            <RequireAuth>
              <Outlet />
            </RequireAuth>
          }
          path="ujian/*"
        >
          <Route element={<Ujian />} path=":ujianId" />
        </Route>
      </Routes>
    </React.Fragment>
  )
}

export default App
