import * as React from 'react'

import UjianTimeoutDialog from './ujian-timeout-dialog.jsx'

import Countdown from 'react-countdown'
import styled from 'styled-components'

const UjianCountdown = ({ lockUjian, getDuration }) => {
  const [timeDuration, setTimeDuration] = React.useState(null)
  const [isTimeOut, setIsTimeOut] = React.useState(false)

  React.useEffect(() => {
    if (getDuration() !== 0) {
      setTimeDuration(Date.now() + getDuration())
    }
  }, [getDuration])

  const handleDialogClose = () => {
    setIsTimeOut(false)
    setTimeDuration(Date.now() + 0)
    lockUjian()
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed && isTimeOut) {
      return <UjianTimeoutDialog handleClose={handleDialogClose} open={isTimeOut} />
    }

    return (
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    )
  }

  if (!timeDuration) return <span>Loading...</span>

  return (
    <CountDownTime>
      <Countdown key={timeDuration} date={timeDuration} onComplete={() => setIsTimeOut(true)} renderer={renderer} />
    </CountDownTime>
  )
}

const CountDownTime = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  font-size: 32px;
  border: 3px solid #63c2de;
  line-height: 200px;
  text-align: center;
  background: transparent;
  margin: auto;
  span {
    color: #000;
  }
`
export default UjianCountdown
