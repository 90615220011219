import * as React from 'react'

import { useAuth } from '../../context/auth/auth-context.jsx'
import Logo from '../../images/logo.png'

import { useFormik } from 'formik'
import { Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

function Login() {
  const [loading, setLoading] = React.useState(false)
  const { login, user } = useAuth()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Email is required!'),
      password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters')
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true)
        await login(values)
      } catch (error) {
        toast.error('Invalid email or password')
      } finally {
        setLoading(false)
      }
    }
  })

  React.useEffect(() => {
    if (user) {
      navigate('/beranda')
    }
  }, [user])

  return (
    <div className="flex-row align-items-center">
      <Container>
        <div className="d-flex justify-content-center">
          <img alt="Depa's Infection Logo" className="my-5" height="80" src={Logo} width="80" />
        </div>
        <Row className="justify-content-center">
          <Col md="6">
            <Card className="p-4">
              <Card.Body>
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <h1>Portal Olimpiade</h1>
                  <p className="text-muted">Masuk ke akun Anda </p>
                  <Form.Group>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>
                        <i className="fas fa-envelope" />
                      </InputGroup.Text>
                      <Form.Control
                        isInvalid={formik.errors.email ? formik.touched.email : null}
                        name="email"
                        onChange={formik.handleChange}
                        placeholder="E-mail"
                        type="text"
                        value={formik.values.email}
                      />
                    </InputGroup>
                    <Form.Control.Feedback className={formik.errors.email && formik.touched.email ? 'd-block' : ''} type="invalid">
                      {formik.errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <InputGroup className="mb-4">
                      <InputGroup.Text>
                        <i className="fa fa-lock" />
                      </InputGroup.Text>
                      <Form.Control
                        isInvalid={formik.errors.password ? formik.touched.password : null}
                        name="password"
                        onChange={formik.handleChange}
                        placeholder="Kata sandi"
                        type="password"
                        value={formik.values.password}
                      />
                    </InputGroup>
                    <Form.Control.Feedback className={formik.errors.password && formik.touched.password ? 'd-block' : ''} type="invalid">
                      {formik.errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-between">
                    <Button className="px-4" disabled={loading} type="submit" variant="primary">
                      Masuk
                    </Button>
                    <Button variant="link">
                      <a href="https://depasinfection.com/participant/reset-password">Lupa password</a>
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Login
